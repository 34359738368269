import Cookies from 'js-cookie';

// Utility function to check if the user is logged in
export const isLoggedIn = () => {
    const token = Cookies.get('access_token');
    return !!token;
};

// Utility function to perform logout
export const logout = () => {
    // Remove the authentication token from cookies
    Cookies.remove('access_token');
    Cookies.remove('user_email');
    Cookies.remove('name');
    Cookies.remove('picture');
        // Optionally, you might want to clear any other related data, such as user info or settings
    // Cookies.remove('user_info');

    // Redirect to the login page or home page after logging out
    window.location.href = '/login'; // or any other route you'd like to redirect to
};