import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './Routes'; // Import routes
import { ToastContainer } from 'react-toastify';
import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { faArrowLeft, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';

const isProd = process.env.NODE_ENV === 'production';
Modal.setAppElement('#root');

export default function App() {
  // Use useState to track the icon state
  const [menuIcon, setMenuIcon] = useState(faArrowLeft);

  const handleSidebarBtnClick = () => {
    const sidebar = document.getElementById("sidebar-container");
    sidebar.classList.toggle("active");

    // Toggle the icon based on sidebar state
    if ($("#sidebar-container").hasClass("active")) {
      setMenuIcon(faArrowLeft);  // Set to "arrow left" when active
    } else {
      setMenuIcon(faBars);  // Set to "bars" when inactive
    }
  };

  return (
    <BrowserRouter>
      <div className="app-container">
        <div className="content-container px-0">
          <button className="sidebar-toggle" onClick={handleSidebarBtnClick}>
            <FontAwesomeIcon icon={menuIcon} />
          </button>
          <AppRoutes /> {/* Use the centralized routing component */}
          <ToastContainer />
        </div>
      </div>
    </BrowserRouter>
  );
}
