import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import BaseRequest from '../../Api/Base';
import Cookies from 'js-cookie';
import styles from './Login.module.css';

const Login = () => {
    const api = new BaseRequest();

    const handleLoginSuccess = async (response) => {
        try {
            const token = response.credential;
            const params = { code: token };
            const data = await api.auth_google(params);

            if (data.access_token) {
                Cookies.set('access_token', data.access_token, { expires: 7 });
                Cookies.set('user_email', data.email);
                Cookies.set('name', data.name);
                Cookies.set('picture', data.picture);
                window.location.href = "/";
            } else {
                console.error('Login failed:', data);
            }
        } catch (error) {
            console.error('Error during login:', error);
        }
    };

    const handleLoginFailure = (error) => {
        console.error('Login failed:', error);
    };

    return (
        <GoogleOAuthProvider clientId="911333972651-0j9jrhs844kvtb0v3l6dve550d376hq7.apps.googleusercontent.com">
             <div className={styles.loginContainer}>
                <div className={styles.loginContent}>
                    <h1 className={styles.welcomeText}>Welcome to StopLoss</h1>
                    <p className={styles.description}>
                        Unlock the power of trading with advanced tools and insights. Join our community and elevate your trading experience.
                    </p>
                    <div className={styles.googleLoginContainer}>
                        <GoogleLogin
                            onSuccess={handleLoginSuccess}
                            onError={handleLoginFailure}
                            text="Login with Google"
                            className={styles.googleLoginButton}
                            logo_alignment="center"
                        />
                    </div>
                    <div className={styles.extraContent}>
                        <p>Why choose us?</p>
                        <ul className='ps-4'>
                            <li className='ps-2'>Advanced trading tools</li>
                            <li className='ps-2'>Real-time insights</li>
                            <li className='ps-2'>User-friendly interface</li>
                            <li className='ps-2'>Community support</li>
                        </ul>
                    </div>
                </div>
            </div>
        </GoogleOAuthProvider>
    );
};

export default Login;
