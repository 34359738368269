import axios from 'axios';
import { toast } from '../utils';
import Cookies from 'js-cookie';

class BaseRequest {
  constructor() {
    this.loading = false;
    this.error = null;
    this.errorData = null;
    this.fastapihost = "/api/3m";
    this.host = "/api";
    this.host_scheduler = "/scheduler";
  }

  getHeaders() {
    const token = Cookies.get('access_token');
    return {
      'Content-Type': 'application/json',
      'access_token': token,
    };
  }

  getEndpoint($path) {
    return `${$path}`;
  }

  async get(endpoint, params = {}) {
    this.setLoading(true);
    try {
      const response = await axios.get(this.getEndpoint(endpoint), {
        params,
        headers: this.getHeaders(),
      });
      return response.data;
    } catch (error) {
      return error.response
      throw this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async post(endpoint, data = {}) {
    this.setLoading(true);
    try {
      const response = await axios.post(this.getEndpoint(endpoint), data, {
        headers: this.getHeaders(),
      });
      return response.data;
    } catch (error) {
      return error.response
      throw this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async apost(endpoint, data = {}) {
    this.setLoading(true);
    try {
      const response = await axios.post(`${endpoint}`, data, {
        headers: this.getHeaders(),
      });
      return response.data;
    } catch (error) {
      return error;
    } finally {
      this.setLoading(false);
    }
  }

  async aget(endpoint, data = {}) {
    this.setLoading(true);
    try {
      const response = await axios.get(endpoint, {
        headers: this.getHeaders(),
        params: data, // Convert data into URL parameters
      });
      return response.data;
    } catch (error) {
      return error;
    } finally {
      this.setLoading(false);
    }
  }
  
  async delete(endpoint, params = {}) {
    this.setLoading(true);
    try {
      const response = await axios.delete(`/api${endpoint}`, {
        params,
        headers: this.getHeaders(),
      });
      return response.data;
    } catch (error) {
      return error
      throw this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  setLoading(status) {
    this.loading = status;
  }

  handleError(error) {
    return error;
    // Handle errors as needed, e.g., log to a service or display a user-friendly message
    console.error('API call error:', error);
    if (error.response) {
      this.errorData = `API Error: ${error.response.data.error}`;
      // Server responded with a status other than 200 range
      this.error = new Error(`API Error: ${error.response.status} ${error.response.statusText}`);
    } else if (error.request) {
      // No response from the server
      this.error = new Error('API Error: No response from server');
    } else {
      // Other errors
      this.error = new Error(`API Error: ${error.message}`);
      this.errorData = new Error(`API Error: ${error.response.data.error}`);
    }

    return error;
    throw this.error;
  }

  qeHeaders() {
    const token = Cookies.get('access_token');
    return {
        'accept': `application/json`,
        'Content-Type': `application/json`,
        'Access-Control-Allow-Origin': `*`,
        'api_key': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiUk1JUGFydG5lciJ9.OOBg1GSEWGcE14c_mIrfVCYMNfTtdrNHNCgpUoAw460",
        'access_token': token,
      }
  }

  async python($payload) {
    var $endpoint = this.fastapihost + "/autocode";
    if ($payload.action == "list") {
      $endpoint = this.fastapihost + "/getautocodeData";
    }
    const response = await axios.post($endpoint, $payload, {
      headers: this.qeHeaders(),
    });
    return response.data;
  }

  async get_scheduled_info($id) {
    var $endpoint = this.host_scheduler + "/schedule-workflow/python_" + $id;
    var $response = this.get($endpoint)
    return $response;
  }

  async workflow(action, payload, method = 'POST') {
    var endpoint = this.fastapihost + "/workflow/" + action;
  
    if (method === 'GET') {
      // Construct query parameters for GET request
      const params = new URLSearchParams(payload).toString();
      endpoint += '?' + params;
    }
  
    const config = {
      method: method,
      url: endpoint,
      headers: this.qeHeaders(),
    };
  
    if (method === 'POST' || method == "PUT") {
      config.data = payload;
    }
  
    try {
      const response = await axios(config);
      return response.data;
    } catch (error) {
      console.error("Error making request:", error);
      throw error;
    }
  }

  async workflow_items(action, payload, method = 'POST') {
    var endpoint = this.fastapihost + "/workflow/items/" + action
  
    if (method === 'GET') {
      // Construct query parameters for GET request
      const params = new URLSearchParams(payload).toString();
      endpoint += '?' + params;
    }
  
    const config = {
      method: method,
      url: endpoint,
      headers: this.qeHeaders(),
    };
  
    if (method === 'POST') {
      config.data = payload;
    }
  
    try {
      const response = await axios(config);
      return response.data;
    } catch (error) {
      // toast
      console.error("Error making request:", error);
      return null
      // console.log(error)
    }
  }

  async autocode($payload) {
    var $endpoint = this.fastapihost + "/autocode";
    return this.apost($endpoint, $payload);
  }


  async publish_instrument($payload) {
    var $endpoint = this.host + "/market/published_instrument";
    return this.apost($endpoint, $payload);
  }

  async get_instrument_info($payload) {
    var $endpoint = this.host + "/market/instruments_by_symbol";
    console.log($endpoint, $payload)
    return this.aget($endpoint, $payload);
  }
  

  async schedule_workflow($payload) {
    var $endpoint = this.host_scheduler + "/schedule-workflow";
    return this.apost($endpoint, $payload);
  }

  async symbol_publish($params) {
    var $endpoint = this.host + "/symbols/publish";
    return this.get($endpoint, $params);
  }

  async get_klines($params) {
    var $endpoint = this.host + "/klines";
    return this.get($endpoint, $params);
  }

  async get_symbols($params) {
    var $endpoint = this.host + "/market/instruments_by_type"
    return this.get($endpoint, $params);
  }

  async auth_google($params) {
    var $endpoint = this.host + "/auth/callback"
    return this.get($endpoint, $params);
  }

  async get_user_lists($params) {
    var $endpoint = this.host + "/auth/users"
    return this.get($endpoint, $params);
  }
  
  async create_paper_trade($payload) {
    var $endpoint = this.host + "/market/paper_trading/create"
    return this.post($endpoint, $payload);
  }
  async get_trding_insights() {
    var $endpoint = this.host + "/market/trading-insights"
    return this.get($endpoint);
  }
  
}

export default BaseRequest;
