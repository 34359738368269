import { toast as t } from 'react-toastify';

export function toast($msg, $status = "success") {

  if ($status == "error") {
    t.error($msg);
  }
  else {
    t.success($msg);
  }
}

